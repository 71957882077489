import $ from 'jquery';
import Dropzone from 'dropzone';

const defaultOptions = {
    autoProcessQueue: false,
    parallelUploads: 1,
    uploadMultiple: false,
    maxFiles: 1,
    paramName: 'import_file',
    hiddenInputContainer: '.selenium-dropzone-container',
    clickable: '.upload-container--link',
    previewsContainer: '.dropzone-previews',
};

function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
}

export function createFileUploader(element, options) {
    Dropzone.autoDiscover = false;
    const submitButton = $(element).find('[type="submit"]');

    let dropTarget = $('#drop-target');
    if (!dropTarget.length) {
        dropTarget = $('<div>')
            .attr('id', 'drop-target')
            .addClass(['tw-fixed', 'tw-top-0', 'tw-right-0', 'tw-bottom-0', 'tw-left-0', 'tw-z-[10000]', '!tw-hidden'])
            .append(
                $('<div>').addClass([
                    'tw-absolute',
                    'tw-top-[7px]',
                    'tw-right-[7px]',
                    'tw-bottom-[7px]',
                    'tw-left-[7px]',
                    'tw-bg-[#EAEAEA7F]',
                    'tw-border-[5px]',
                    'tw-border-dashed',
                    'tw-border-[#BBBBBBFF]',
                    'tw-pointer-events-none',
                ])
            );

        $(document.body).append(dropTarget);
    }

    options = $.extend(defaultOptions, options, {
        init: function () {
            const dz = this;

            submitButton.off('click');
            submitButton.on('click', function (e) {
                if (!dz.files.length) {
                    alert('Моля, изберете файл за импортиране');
                    return false;
                }

                dz.processQueue();
            });

            dz.on('maxfilesexceeded', function (file) {
                dz.removeAllFiles();
                dz.addFile(file);
            });

            dz.on('addedfile', function (file) {
                if (file.size > this.options.maxFilesize * 1024 * 1024) {
                    const body = 'Файлът надвишава максималния допустим размер от' + this.options.maxFilesize + " MB.";
                    modal.alert(i18n.dialogs.warning, body, i18n.dialogs.ok);
                    dz.removeFile(file);

                    return;
                }

                $('.drop-zone__file-name').css('padding-top', '10px');
            });
        },
        processing: function () {
            submitButton
                .val('Качва се...')
                .addClass('!tw-text-gray-500')
                .addClass('hover:!tw-bg-[#eee]')
                .prop('disabled', true);
        },
        success: function (file, response) {
            window.location = response.redirectTo;
        },
        error: function (file, response) {
            if (response.redirectTo) {
                window.location = response.redirectTo;
            }
        },
        dragenter: function (e) {
            e.preventDefault();
            dropTarget.removeClass('!tw-hidden');
        },
    });

    const dropZone = new Dropzone(element, options);

    $(document.body).on('dragover', preventDefaults);
    $(document.body).on('dragenter', function (e) {
        preventDefaults(e);
        dropTarget.removeClass('!tw-hidden');
    });
    $(dropTarget).on('dragleave', function (e) {
        preventDefaults(e);
        dropTarget.addClass('!tw-hidden');
    });
    $(document.body).on('drop', function (event) {
        event.preventDefault();
        dropTarget.addClass('!tw-hidden');
        const files = event.originalEvent.dataTransfer.files;
        if (files.length) {
            $.each(files, function (index, file) {
                dropZone.addFile(file);
            });
        }
    });

    return dropZone;
}